import { Link } from "react-router-dom";
import { CartelPage } from "../pages/CartelPage";

export function BaseCGU() {
  return (
    <div>
      <h3>Conditions générales d'utilisation</h3>
      <div class="cartel-legal">
        <h1>PRÉAMBULE</h1>
        <p>
          Les présentes « conditions générales d'utilisation » ont pour objet
          l'encadrement juridique de l'utilisation du site
          noussommeslacompagnie.fr (ci-après désigné comme « le site ») et de
          ses services.
        </p>
        <p>
          Ce contrat est conclu entre :
          <ul>
            <li>Le gérant du site internet, ci-après désigné « l'éditeur »</li>
            <li>
              Toute personne physique ou morale souhaitant accéder au site et à
              ses services, ci-après appelé « l'utilisateur »
            </li>
          </ul>
        </p>
        <h1>Accès aux services</h1>
        <p>
          L'Utilisateur du site noussommeslacompagnie.fr a accès gratuitement
          aux services suivants :
          <ul>
            <li>Création de comptes</li>
            <li>Navigation sur les pages de communications</li>
            <li>
              Commande de produits selon les Conditions Générales de Vente du
              site
            </li>
          </ul>
        </p>
        <p>
          Les autres services du site sont conditionnés par la commande d'un des
          produits du site via le partenaire technique Stripe dans le cadre
          défini dans les conditions générales de ventes du site.
        </p>
        <p>
          Tout Utilisateur ayant accès a internet peut accéder gratuitement et
          depuis n'importe où au site. Les frais supportés par l'Utilisateur
          pour y accéder (connexion internet, matériel informatique, etc.) ne
          sont pas à la charge de l'Éditeur.
        </p>
        <p>
          Le site et ses différents services peuvent être interrompus ou
          suspendus par l'Éditeur, notamment à l'occasion d'une maintenance,
          sans obligation de préavis ou de justification.
        </p>
        <h1>Responsabilité de l'utilisateur</h1>
        <p>
          L'Utilisateur est responsable des risques liés à l'utilisation de son
          identifiant de connexion et de son mot de passe.
        </p>
        <p>
          Le mot de passe de l'Utilisateur doit rester secret. En cas de
          divulgation de mot de passe, l'Éditeur décline toute responsabilité.
        </p>
        <p>
          L'Utilisateur assume l'entière responsabilité de l'utilisation qu'il
          fait des informations et contenus présents sur le site.
        </p>
        <p>
          Tout usage du service par l'Utilisateur ayant directement ou
          indirectement pour conséquence des dommages doit faire l'objet d'une
          indemnisation au profit du site.
        </p>
        <p>
          Le site permet aux membres de réaliser les actions nécessaires et
          prévues par l'Éditeur dans le cadre des jeux commandés sur celui-ci.
        </p>
        <p>
          La possibilité pour un Utilisateur de réaliser les différentes actions
          et d'accéder aux différentes pages du Site est à la discrétion totale
          de l'Éditeur.
        </p>
        <h1>Responsabilité de l'Éditeur</h1>
        <p>
          Tout dysfonctionnement du serveur ou du réseau ne peut engager la
          responsabilité de l'Éditeur. De même, la responsabilité du site ne
          peut être engagée en cas de force majeure ou du fait imprévisible et
          insurmontable d'un tiers.
        </p>
        <p>
          Le site s'engage à mettre en œuvre tous les moyens nécessaires pour
          garantir la sécurité et la confidentialité des données. Toutefois, il
          n'apporte pas une garantie de sécurité totale. L'Éditeur se réserve
          également la faculté d'une non-garantie de la fiabilité des sources,
          bien que les informations diffusées su le site soient réputées
          fiables.
        </p>
        <h1>Propriété intellectuelle</h1>
        <p>
          Les contenus du site (logos, textes, éléments graphiques, vidéos,
          etc.) sont protégés par le droit d'auteur, en vertu du Code de la
          propriété intellectuelle. L'Utilisateur devra obtenir l'autorisation
          de l'éditeur du site avant toute reproduction, copie ou publication de
          ces différents contenus.
        </p>
        <p>
          Ces derniers peuvent être utilisés par les utilisateurs à des fins
          privées ; tout usage commercial est interdit.
        </p>
        <p>
          L'Utilisateur est entièrement responsable de tout contenu qu'il met en
          ligne et il s'engage à ne pas porter atteinte à un tiers. L'Éditeur du
          site se réserve le droit de modérer ou de supprimer librement et à
          tout moment les contenus mis en ligne par les utilisateurs, et ce sans
          justification.
        </p>
        <p>
          En cas d'usage non conforme du site par l'Utilisateur, l'Éditeur se
          réserver le droit de lui supprimer l'accès au site de celui-ci sans
          préavis ni justification.
        </p>
        <h1>Données personnelles</h1>
        <p>
          L'Utilisateur doit obligatoirement fournir des informations
          personnelles pour procéder à son inscription sur le site. L'adresse
          électronique (e-mail) de l'utilisateur pourra notamment être utilisée
          par le site pour la communication d'informations diverses et la
          gestion du compte et de ses commandes.
        </p>
        <p>
          L'Éditeur s'engage à collecter, traiter ou conserver vos données
          personnelles pour les finalités légitimes, déterminées et pertinentes
          suivantes :
          <ul>
            <li>Gestion du compte</li>
            <li>Gestion des commandes</li>
            <li>Envoi de message sur les espaces communautaires</li>
            <li>Fonctionnement technique du site</li>
          </ul>
        </p>
        <p>
          Des données d'utilisation anonymisées peuvent également être utilisés
          par l'Éditeur à des fins statistiques.
        </p>
        <p>
          L'Éditeur garantie le respect de la vie privée de l'utilisateur,
          conformément à la loi n°78-17 du 6 janvier 1978 relative à
          l'informatique, aux fichiers et aux libertés.
        </p>
        <p>
          En vertu des articles 39 et 40 de la loi en date du 6 janvier 1978,
          l'Utilisateur dispose d'un droit d'accès, de rectification, de
          suppression et d'opposition de ses données personnelles.
        </p>
        <p>
          L'Utilisateur exerce ce droit via :
          <ul>
            <li>
              Par mail à l'adresse électronique précisée dans la section
              Mentions Légales du site
            </li>
          </ul>
        </p>
        <h1>Liens hypertextes</h1>
        <p>
          Les domaines vers lesquels mènent les liens hypertextes présents sur
          le site n'engagent pas la responsabilité de l'Éditeur qui n'a pas de
          contrôle sur ces liens.
        </p>
        <h1>ÉVOLUTION DES CONDITIONS GÉNÉRALES D'UTILISATION</h1>
        <p>
          Le site se réserve le droit de modifier les clauses de ces conditions
          générales d'utilisation à tout moment et sans justification.
        </p>
        <p>
          Toute modification des conditions d'utilisation sera communiqué aux
          utilisateurs membres via au moins une des méthodes suivantes :
          <ul>
            <li>Envoi d'un mail à l'adresse électronique de l'Utilisateur</li>
            <li>
              Présentation d'un avertissement relatif à cette modification lors
              de la prochaine ouverture du site par l'Utilisateur
            </li>
          </ul>
        </p>
        <h1>DUREE DU CONTRAT</h1>
        <p>
          La durée du présent contrat est indéterminée. Le contrat produit ses
          effets à l'égard de l'Utilisateur à compter du début de l'utilisation
          du service.
        </p>
        <h1>DROIT APPLICABLE</h1>
        <p>
          En cas de difficultés dans l'application du présent contrat, le client
          a la possibilité, avant toute action en justice, de rechercher un
          règlement à l'amiable. Les réclamations ou contestations seront
          toujours reçues avec une attentive bienveillance, la bonne foi étant
          toujours présumée chez celui qui prend la peine d'exposer sa
          situation. A défaut de solution amiable, les tribunaux français seront
          seuls compétents.
        </p>
      </div>
    </div>
  );
}

export function CGUPage() {
  return (
    <CartelPage>
      <BaseCGU />
    </CartelPage>
  );
}

export function CGURegister() {
  return (
    <>
      <div className="cartel expand" style={{ height: "20rem" }}>
        <BaseCGU />
      </div>
      <Link to="..?register=true">
        <button
          className="button is-fullwidth is-off"
          style={{ marginTop: "1rem" }}
        >
          Retour
        </button>
      </Link>
    </>
  );
}
