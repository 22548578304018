import { Link } from "react-router-dom";

function Footer() {
  return (
    <section className="transparent-footer">
      <div className="has-text-centered">
        <Link to="/legal">
          <h2>· Mentions Légales ·</h2>
        </Link>
      </div>
    </section>
  );
}

export default Footer;
