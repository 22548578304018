import { useNavigate } from "react-router";

export function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <>
      <div className="columns">
        <div
          className="column is-half-desktop is-offset-one-quarter-desktop is-centered"
          style={{ textAlign: "center", paddingTop: "3rem" }}
        >
          <h1>404</h1>
          <p>Vous devriez faire demi-tour.</p>
          <button
            className="button is-active is-rounded"
            style={{ marginTop: "2rem" }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            Retour à l'accueil
          </button>
        </div>
      </div>
    </>
  );
}
