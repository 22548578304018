import { Link } from "react-router-dom";
import Config from "../../../Config";

export function BodyNoStatus() {
  return (
    <>
      <div className="block-content" style={{ flexGrow: 1 }}>
        {" "}
      </div>
      <div className="block-content text-center" style={{ flexGrow: 3 }}>
        <div className="columns normal">
          <div className="column">
            <h3 style={{ marginBottom: "1rem" }}>
              Bienvenue dans la Compagnie
            </h3>
            <p
              className="thin"
              style={{ fontWeight: 200, margin: "0 auto", maxWidth: "70vw" }}
            >
              Afin de nous rejoindre, vous devez commander l'histoire
              correspondant à votre ville
            </p>
            {Config.stripe?.lyon ? (
              <Link
                className="button is-family-monospace is-active is-centered"
                style={{ marginTop: "1rem", marginBottom: "3rem" }}
                to={
                  Config.stripe.lyon.url +
                  (Config.stripe.lyon.promoCode
                    ? `?prefilled_promo_code=${Config.stripe.lyon.promoCode}`
                    : "")
                }
              >
                Ouvrir la boutique
              </Link>
            ) : (
              <></>
            )}
            <p
              className="thin"
              style={{
                fontWeight: 200,
                fontSize: "90%",
                margin: "0 auto",
                maxWidth: "85vw",
              }}
            >
              Si vous avez déjà commandé, attendez notre courrier pour démarrer
              l'aventure
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
