import { useEffect, useRef } from "react";

export type LoginAnimation = "stop" | "appears" | "switchLetter";

export function useLoginAnimation(
  aboveAnimation: LoginAnimation,
  targetText: string,
  aboveText: string,
  setAboveText: (value: React.SetStateAction<string>) => void
) {
  const interval = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    switch (aboveAnimation) {
      case "appears":
        interval.current = setInterval(() => {
          if (aboveText.length < targetText.length) {
            setAboveText((c) => targetText.substring(0, c.length + 1) + "_");
          }
        }, 75);
        break;
      case "switchLetter":
        interval.current = setInterval(() => {
          var newText = "";
          for (let i = 0; i < aboveText.length; i++) {
            if (i >= targetText.length) {
              newText = newText.concat(
                aboveText.substring(i, aboveText.length)
              );
              break;
            }
            let currentLetter = aboveText[i];
            let targetLetter = targetText[i];

            var nextLetter = targetLetter;
            if (currentLetter < targetLetter) {
              nextLetter = String.fromCharCode(currentLetter.charCodeAt(0) + 1);
            } else if (currentLetter > targetLetter) {
              nextLetter = String.fromCharCode(currentLetter.charCodeAt(0) - 1);
            }
            newText = newText.concat(nextLetter);
          }
          if (newText.length < 100) {
            setAboveText(newText);
          }
        }, 50);
        break;
      case "stop":
        if (interval.current) {
          clearInterval(interval.current);
        }
        interval.current = null;
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [aboveAnimation, targetText, aboveText, setAboveText]);
}
