import { Link } from "react-router-dom";
import { CartelPage } from "../pages/CartelPage";

export function BaseCGVentes() {
  return (
    <div>
      <h3>Conditions générales de ventes</h3>
      <div className="cartel-legal">
        <h1>PRÉAMBULE</h1>
        <p>
          Les conditions générales de vente, ci-après dénommées « les présentes
          » ou « les CGV », définissent les droits et obligations des parties
          dans le cadre de ventes effectuées depuis le site internet
          noussommeslacompagnie.fr, ci-après dénommé « le site ». Elles sont
          conclues entre, d'une part, la société par actions simplifiées La
          Compagnie des Projets Secondaires éditrice du site
          NousSommesLaCompagnie.fr ci après-dénommée « le vendeur », et d'autre
          part, les personnes souhaitant effectuer une commande de produit(s)
          via le site, ci-après dénommées « le client ».
        </p>
        <p>
          La commande de produits présentés sur le site est soumise à
          l'acceptation par le client de l'intégralité des présentes telles
          qu'exposées ci-après. Ces conditions générales de vente prévalent sur
          toutes les autres conditions, et s'imposent pour le client sans égard
          pour les documents publicitaires ou promotionnels pouvant être émis
          par le vendeur. Le vendeur se réserve le droit d'adapter ou de
          modifier à tout moment ses CGV. En cas de modification, les CGV
          applicables à la commande seront celles en vigueur au jour du paiement
          définitif de la commande.
        </p>
        <h1>PRODUITS</h1>
        <p>
          Les produits proposés à la vente par le vendeur sur le site sont ceux
          mentionnés sur le site. Le vendeur se réserve le droit de modifier le
          contenu de son site à tout moment et sans préavis, notamment pour
          inclure ou supprimer des références ou pour modifier certains prix de
          vente.
        </p>
        <p>
          Les produits sont conformes à la réglementation en vigueur en France.
        </p>
        <h1>INFORMATIONS REGLEMENTAIRES</h1>
        <p>Le numéro de TVA intra-communautaire du Vendeur est FR42877899286</p>
        <h1>ACCEPTATION</h1>
        <p>
          Le client déclare avoir pris connaissance et accepté sans aucune
          réserve les présentes CGV, qui s'appliquent au contrat de vente.{" "}
        </p>
        <p>
          L'acceptation par le client des présentes conditions est matérialisée
          par la prise de commande ou de précommande électronique et son
          règlement depuis la page de notre partenaire Stripe.
        </p>
        <h1>DISPONIBILITE</h1>
        <p>
          Les offres de produits et les prix indiqués sont valables tant qu'ils
          figurent sur le site. Le vendeur dispose de 72h pour accepter la
          commande passée par le client.
        </p>
        <h1>PRIX</h1>
        <p>
          Le prix des produits est exprimé en euros TTC. Le montant total d'une
          commande comprend le prix des produits et les frais de livraison. Le
          vendeur se réserve le droit de modifier ses prix à tout moment et sans
          préavis. Cependant, pour toute commande acceptée, elle garantit les
          tarifs pratiqués à la date de réception de la commande.
        </p>
        <h1>PAIEMENT ET CONDITIONS D'UTILISATION DU MODULE STRIPE</h1>
        <p>
          Le prix des produits est payable le jour de la commande effective. Le
          paiement s'effectue de façon sécurisée via le module de paiement
          électronique mis en place par notre partenaire Stripe, ci-après
          dénommé "module Stripe", soit par débit du compte Stripe du client,
          soit par carte bancaire. La liste précise des cartes bancaires
          acceptées est signifiée au client lors du processus de paiement via le
          module Stripe. Le client effectuant un règlement via le module Stripe
          est réputé avoir pris connaissance et accepté de façon pleine et
          entière le "Règlement sur le respect de la vie privée", les
          "Conditions d'utilisation" et les "Informations essentielles sur le
          paiement et le service" disponibles sur Stripe et dans l'email de
          confirmation de paiement adressé au client qui vient de finaliser une
          commande. Le vendeur ne saurait être tenue pour responsable des
          modifications, erratums... susceptibles d'apparaître dans les
          informations diffusées par Stripe. Par ailleurs, le vendeur se réserve
          le droit de refuser toute commande d'un client avec lequel existerait
          un litige.
        </p>
        <h1>LIVRAISON</h1>
        <p>
          Les produits ne pourront être livrés qu'à l'adresse de livraison
          indiquée par le client lors de la prise de commande. En cas d'erreur,
          d'omission ou d'imprécision dans la transmission des informations de
          livraison (nom, adresse...), le vendeur ne saurait être tenue pour
          responsable de l'impossibilité dans laquelle elle pourrait alors se
          trouver de livrer la commande.
        </p>
        <p>
          A compter de la confirmation du paiement de la commande, le vendeur
          dispose de 72h pour confirmer celle-ci. La livraison des commandes est
          assurée par un prestataires technique.
        </p>
        <p>
          En dehors des précommandes, la livraison de la commande intervient
          dans les délais précisés lors de sa réalisation ou de sa confirmation.
          Si de tels délais ne sont pas précisés la livraison intervient alors,
          au plus tard, dans les 30 (TRENTE) jours suivant sa réalisation.
        </p>
        <p>
          Dans le cadre des offres dites de "précommande" dont les articles sont
          présentés comme tel, la date d'expédition donnée est indicative. Dans
          le cas où le vendeur ne pourrait assurer l'expédition de la commande
          avant la date mentionnée, le Client se verra proposer un remboursement
          ou une expédition à date ultérieure.
        </p>
        <h1>RÉCEPTION ET MODALITÉS DE RETOUR</h1>
        <p>
          Le Client dispose d'un délai de 14 (QUATORZE) jours pour exercer son
          droit de rétractation et effectuer une demande de retour pour les(s)
          Produit(s) qu'il a commandé(s). Le délai de rétractation court à
          compter de la date de livraison des Produits à l'adresse de livraison
          indiquée par le Client, et en cas d'absence de ce dernier, à compter
          de la date de première présentation du colis contenant les Produits.
        </p>
        <p>
          Ce délai de rétractation est interrompu lorsque le client active le
          contenu numérique lié à sa commande (accès à des actions spécifiques
          sur le site). Cette renonciation au délai de rétractation lui est
          signifiée de nouveau par l'éditeur au moment de l'activation.
        </p>
        <p>
          Une fois la demande de rétractation effectuée, le Client dispose
          ensuite d'un délai de 16 (SEIZE) jours pour renvoyer sa commande, par
          voie postale, par transporteur ou en Point Retrait à l'adresse qui lui
          sera communiquée par le Vendeur.
        </p>
        <p>
          Les retours sont gratuits pour le Client, à condition de retourner au
          Vendeur le bordereau de livraison émis par le Service Clients de ce
          dernier. Tout retour effectué par le Client sans utilisation du
          bordereau de livraison émis par le Vendeur sera entièrement à sa
          charge.
        </p>
        <p>
          Le ou les produits doivent être retournés dans leur emballage
          d'origine, en parfait état de revente et accompagnés de la facture. En
          cas de détérioration de l'emballage ou du produit, le Vendeur pourra
          refuser le retour, le remplacement ou le remboursement. Tout
          remboursement est exclu dans l'hypothèse où le produit était retourné
          endommagé.
        </p>
        <h1>LITIGES</h1>
        <p>
          En cas de difficultés dans l'application du présent contrat, le client
          a la possibilité, avant toute action en justice, de rechercher un
          règlement à l'amiable. Les réclamations ou contestations seront
          toujours reçues avec une attentive bienveillance, la bonne foi étant
          toujours présumée chez celui qui prend la peine d'exposer sa
          situation. A défaut de solution amiable, les tribunaux français seront
          seuls compétents.
        </p>
        <p>
          Le client est informé qu'il a la faculté de recourir à une médiation
          conventionnelle, notamment sous l'égide de la Commission d'évaluation
          et de contrôle de la médiation de la consommation, dans les conditions
          prévues dans le Code de la consommation.
        </p>
      </div>
    </div>
  );
}

export function CGVentesPage({
  extraLink,
}: {
  extraLink?: { to: string; text: string };
}) {
  return (
    <CartelPage extraLink={extraLink}>
      <BaseCGVentes />
    </CartelPage>
  );
}

export function CGVentesRegister() {
  return (
    <>
      <div className="cartel" style={{ height: "20rem" }}>
        <BaseCGVentes />
      </div>
      <Link to="..?register=true">
        <button
          className="button is-fullwidth is-off"
          style={{ marginTop: "1rem" }}
        >
          Retour
        </button>
      </Link>
    </>
  );
}
