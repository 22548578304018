import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/ReduxStore";
import { StatusThunk } from "../../../redux/status/StatusThunk";
import Config from "../../../Config";
import { LoadingPage } from "../../LoadingPage";

export function BodyWaitingActivation(props: { code?: string }) {
  const [activationCode, setActivationCode] = useState<string>(
    props.code ? props.code : ""
  );
  const [errorMessage, setErrorMessage] = useState<{
    str: string;
    code?: string;
  } | null>(null);

  const [checkingCode, setCheckingCode] = useState<boolean>(false);
  const [precodeAvailable, setPrecodeAvailable] = useState<boolean>(false);
  const [confirmNew, setConfirmNew] = useState<boolean>(false);

  const statusState = useSelector((state: RootState) => state.status);

  const dispatch = useDispatch<AppDispatch>();

  if (props.code) {
    // A code is set in redux, check if available
    const codeTested = props.code;

    if (!checkingCode) {
      fetch(Config.endpoint + "user/status/find/" + codeTested).then((resp) => {
        console.log(resp.status);
        if (resp.status !== 200) {
          setErrorMessage({
            str: "Code indisponible",
            code: "UNAVAILABLE_CODE",
          });
          dispatch(unsetActivationCode());
        } else {
          setPrecodeAvailable(true);
        }
      });
      setCheckingCode(true);
    }

    if (precodeAvailable) {
      if (
        statusState.status &&
        statusState.status.code &&
        statusState.status?.code !== codeTested.toUpperCase()
      ) {
        return <ConfirmChangeForm code={codeTested} />;
      } else {
        return <ConfirmNewForm code={codeTested} />;
      }
    } else {
      return <LoadingPage />;
    }
  }

  if (statusState.error !== null && statusState.error !== errorMessage?.code) {
    var message = statusState.error;
    switch (statusState.error) {
      case "INVALID_CODE":
        message = "Code invalide";
        break;
      case "UNKNOWN_CODE":
        message = "Code inconnu";
        break;
    }
    setErrorMessage({ str: message, code: statusState.error });
  }

  function validateCode() {
    if (activationCode.length < 6) {
      setErrorMessage({
        str: "Code trop court",
        code: "TOO_SHORT_CODE",
      });
      return;
    } else {
      //dispatch(StatusThunk.activateStatus(activationCode));
      setConfirmNew(true);
    }
  }

  if (confirmNew) {
    return <ConfirmNewForm code={activationCode} />;
  }

  return (
    <div className="block-content text-center centered-body">
      <div className="columns normal" style={{ marginBottom: "1.5rem" }}>
        <div className="column">
          <h3>La Compagnie est en train de vous contacter...</h3>
          <p className="thin">
            Lors de la réception de votre premier courrier, scannez-le ou
            rentrez votre code d'activation
          </p>
        </div>
      </div>
      <div className="columns" style={{ marginBottom: "15vh" }}>
        <div className="column is-centered">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validateCode();
            }}
          >
            {errorMessage ? (
              <h2 className="is-family-monospace login-tooltip is-nok">
                {errorMessage.str}
              </h2>
            ) : (
              <></>
            )}
            <div className="field has-addons">
              <div className="control">
                <input
                  className="input is-family-monospace"
                  type="text"
                  placeholder="CODE DE 6 CARACTÈRES"
                  maxLength={6}
                  minLength={6}
                  name="code"
                  value={activationCode.toUpperCase()}
                  onChange={(e) => setActivationCode(e.target.value)}
                />
              </div>
              <div className="control">
                <button className="button is-family-monospace is-active">
                  VALIDER
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function ConfirmChangeForm(props: { code: string }) {
  return (
    <div className="block-content text-center centered-body">
      <div className="columns normal" style={{ marginBottom: "0.5rem" }}>
        <div className="column">
          <h3>Une nouvelle aventure</h3>
          <p className="thin">
            Votre compte est actuellement lié à une autre aventure. <br />
            En confirmant cette étape, vous démarrerez une nouvelle mission.
          </p>
        </div>
      </div>
      <ValidationFooter code={props.code} />
    </div>
  );
}

function ConfirmNewForm(props: { code: string }) {
  return (
    <div className="block-content text-center centered-body">
      <div className="columns normal" style={{ marginBottom: "0.5rem" }}>
        <div className="column">
          <h3>Félicitations !</h3>
          <p className="thin">
            Vous êtes sur le point de démarrer votre aventure.
            <br />
            Veuillez confirmer que vous êtes prêt à démarrer la mission.
          </p>
        </div>
      </div>
      <ValidationFooter code={props.code} />
    </div>
  );
}

function ValidationFooter(props: { code: string }) {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="columns" style={{ marginBottom: "15vh" }}>
      <div className="column is-centered">
        <p style={{ marginBottom: "0.2rem", fontSize: "65%" }}>
          En confirmant vous renoncez à votre droit de rétraction
        </p>
        <div className="field">
          <div className="control">
            <button
              className="button is-family-monospace is-active"
              onClick={(e) => {
                e.preventDefault();
                dispatch(StatusThunk.activateStatus(props.code));
              }}
            >
              Confirmer
            </button>
          </div>
          <div className="control" style={{ marginTop: "1vh" }}>
            <button
              className="button is-family-monospace is-red"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Refuser
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
