
export function getLoginError(errorCode: string): string {
    switch (errorCode) {
        case "PASSWORD_NOT_MATCH":
            return "Vérifiez les mots de passe";
        case "DUPLICATE_EMAIL":
            return "Mail déjà utilisé";
        case "PASSWORD_TOO_SHORT":
            return "Mot de passe trop court";
        case "INVALID_INFORMATIONS":
            return "Informations invalides";
        case "MISSING_PASSWORD":
            return "Mot de passe trop court";
        case "MISSING_EMAIL":
            return "Veuillez indiquer un mail";
        default:
            return `Erreur inconnue (${errorCode})`;
    }
}