export function splitToSpan(text: string) {
  var concatenated = [];
  for (let i = 0; i < text.length; i++) {
    concatenated.push(
      <span key={i}>{text.charAt(i) === " " ? "\u00A0" : text.charAt(i)}</span>
    );
  }

  return concatenated;
}

export const validateEmail = (email: string) => {
  return email.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
