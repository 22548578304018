import { createSlice } from "@reduxjs/toolkit";
import { AuthThunks } from "./AuthThunk";

export type UserInfos = {
  email: string | null,
  admin: boolean
}

export interface AuthState {
  initialized: boolean,
  loading: boolean,
  user: UserInfos | null,
  error: string | null,
  logged: boolean,
}

export const AuthStateInitial: AuthState = {
  initialized: false,
  loading: false,
  user: null,
  error: null,
  logged: false,
}

export const AuthSlice = createSlice({
  name: "auth",
  initialState: AuthStateInitial,
  extraReducers: (builder) => {
    // PENDING
    builder.addCase(AuthThunks.login.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.user = null;
      state.logged = false;
    })
    builder.addCase(AuthThunks.check.pending, (state) => {
      state.loading = true;
      state.initialized = false;
      state.error = null;
    })
    builder.addCase(AuthThunks.logout.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    // ERRORS
    builder.addCase(AuthThunks.login.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || "UNKNOWN_ERROR";
      state.logged = false;
      state.user = null;
    })
    builder.addCase(AuthThunks.check.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || "UNKNOWN_ERROR";
      state.initialized = true;
      state.logged = false;
      state.user = null;
    })
    builder.addCase(AuthThunks.logout.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || "UNKNOWN_ERROR";
    })
    // WORKED
    builder.addCase(AuthThunks.login.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.logged = true;
      state.user = payload;
    })
    builder.addCase(AuthThunks.check.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.initialized = true;
      state.logged = true;
      state.user = payload;
    })
    builder.addCase(AuthThunks.logout.fulfilled, (state) => {
      state.loading = false;
      state.logged = false;
      state.user = null;
    })
  }, reducers: {}
},
);

export default AuthSlice.reducer;
