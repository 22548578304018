import { Outlet, useLocation } from "react-router";
import { Navbar } from "../parts/Navbar";
import { AuthRoute } from "../redux/auth/AuthRoutes";
import { useEffect, useState } from "react";

export function NavbarStructure({ noPadding }: { noPadding?: boolean }) {
  const [previousLocation, setPreviousLocation] = useState("");
  const location = useLocation();

  const [helpPopup, setHelpPopup] = useState<{
    node: React.ReactNode;
  }>({ node: null });

  useEffect(() => {
    if (previousLocation !== location.pathname) {
      setHelpPopup({ node: null });
      setPreviousLocation(location.pathname);
    }
  }, [location, previousLocation]);

  return (
    <AuthRoute>
      <div className="full-page">
        <Navbar helpPopup={helpPopup.node} />
        <div className="flex-content" style={noPadding ? { padding: 0 } : {}}>
          <Outlet />
        </div>
      </div>
    </AuthRoute>
  );
}
