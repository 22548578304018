import { createAsyncThunk } from "@reduxjs/toolkit";
import Config from "../../Config";
import { RequestOptions } from "../../tools/RequestOptions";

const activateStatus = createAsyncThunk<any, string, { rejectValue: string }>('status/activate',
  async (activationCode, { rejectWithValue }) => {
    try {
      const response = await fetch(
        Config.endpoint + "a/" + activationCode.toLocaleUpperCase(),
        RequestOptions({ method: "POST" })
      );

      switch (response.status) {
        case 200:
          // Status is ok
          const body = await response.json();
          return body;
        case 404:
          return rejectWithValue("UNKNOWN_CODE");
        case 403:
          return rejectWithValue("INVALID_CODE");
        default:
          return rejectWithValue("ERROR_SERVER");
      }
    } catch (error) {
      console.error(error);
      return rejectWithValue("ERROR_NETWORK");
    }
  });

const currentStatus = createAsyncThunk<any, void, { rejectValue: string }>('status/current',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        Config.endpoint + "user/status/current",
        RequestOptions()
      );
      return await response.json();
    } catch (error) {
      console.error(error);
      return rejectWithValue("ERROR_NETWORK");
    }
  });

const newClue = createAsyncThunk<any, void, { rejectValue: string }>('status/newClue',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        Config.endpoint + "user/status/newClue",
        RequestOptions({ method: "POST" })
      );
      return await response.json();
    } catch (error) {
      console.error(error);
      return rejectWithValue("ERROR_NETWORK");
    }
  });

export const StatusThunk = { activateStatus, currentStatus, newClue }