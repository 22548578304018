import "../../styles/home.scss";
import { useNavigate } from "react-router";
import { BodyFull } from "./body/BodyFull";
import { BodyNoStatus } from "./body/BodyNoStatus";
import { BodyWaitingActivation } from "./body/BodyWaitingActivation";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/ReduxStore";
import { StatusThunk } from "../../redux/status/StatusThunk";
import { StoryStates } from "../../shared/StoryElements";
import { LoadingPage } from "../LoadingPage";

export function HomePage() {
  const navigate = useNavigate();
  const statusState = useSelector((state: RootState) => state.status);

  const dispatch = useDispatch<AppDispatch>();

  if (!statusState.initialized && !statusState.loading) {
    dispatch(StatusThunk.currentStatus());
  }

  function generateBody() {
    if (!statusState.initialized || statusState.loading) {
      return <LoadingPage />;
    } else if (statusState.status) {
      switch (statusState.status.state) {
        case StoryStates.COMMAND:
          return <p>Commande en cours</p>;
        case StoryStates.SENT:
          return <BodyWaitingActivation />;
        case StoryStates.ACTIVATED:
        case StoryStates.DONE:
          return <BodyFull />;
      }
    }

    return <BodyNoStatus />;
  }

  return (
    <>
      <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        {generateBody()}
      </div>

      <div
        className="columns"
        style={{
          flexShrink: 0,
          alignItems: "flex-end",
          marginBottom: "0.05rem",
        }}
      >
        <div className="is-fullwidth column">
          <button
            className="button is-medium is-fullwidth button-cartel-light"
            onClick={(e) => {
              e.preventDefault();
              navigate("settings");
            }}
          >
            Informations du compte
          </button>
        </div>
      </div>
    </>
  );
}
