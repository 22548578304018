import { useEffect, useRef, useState } from "react";

export function LoadingPage() {
  const interval = useRef<NodeJS.Timer | null>(null);
  const [text, setText] = useState<String>("");
  const [direction, setDirection] = useState<boolean>(true);
  const delayMs = 500;

  useEffect(() => {
    interval.current = setInterval(() => {
      if (text.length >= 2) {
        setDirection(false);
      } else if (text.length <= 1) {
        setDirection(true);
      }

      if (direction) {
        setText(`${text}.`);
      } else {
        setText(text.substring(0, text.length - 1));
      }
    }, delayMs);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [text, setText, direction, setDirection]);

  return (
    <>
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div>
          <h1>Chargement</h1>
          <h1>{text || "\u00A0"}</h1>
        </div>
      </div>
    </>
  );
}
