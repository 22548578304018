import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";

import "./styles/bulma.min.scss";
import "./styles/style.scss";
import "./styles/header.scss";
import "./styles/sizeRestriction.scss";
import "./styles/fade.css";
import "./styles/imagesContainer.scss";
import LoginPage from "./pages/login/LoginPage";
import LoginForm from "./pages/login/LoginForm";
import { CGUPage, CGURegister } from "./parts/CGU";
import LegalPage from "./pages/LegalPage";
import { HeaderFooterStructure } from "./structure/HeaderFooterStructure";
import { HomePage } from "./pages/home/HomePageLayout";
import { NavbarStructure } from "./structure/NavbarStructure";
import Logout from "./parts/Logout";
import { NotFoundPage } from "./pages/NotFoundPage";
import { Provider } from "react-redux";
import { ReduxStore } from "./redux/ReduxStore";
import { SettingsPage } from "./pages/SettingsPage";
import PasswordResetForm from "./pages/login/PasswordReset";
import { CGVentesPage } from "./parts/CGVentes";
import { LoadingPage } from "./pages/LoadingPage";
import { useEffect } from "react";
var isFirstTime = true;

export function RootHome() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/home");
  });
  return <LoadingPage />;
}

function Root() {
  useEffect(() => {
    document.title = "La Compagnie Vous Ment";
  }, []);
  const location = useLocation();
  const isLanding = location.pathname === "/";
  isFirstTime = isFirstTime && isLanding;
  const app = (
    <>
      <Provider store={ReduxStore}>
        <Routes>
          <Route path="/home" element={<NavbarStructure />}>
            <Route index element={<HomePage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="legal" element={<LegalPage />} />
            <Route path="cgv" element={<CGVentesPage />} />
            <Route path="cgu" element={<CGUPage />} />
          </Route>
          <Route
            path="/"
            element={
              <HeaderFooterStructure useDelay={isLanding && isFirstTime} />
            }
          >
            <Route index element={<LoginForm />} />

            <Route
              path="/legal"
              element={
                <LegalPage
                  extraLink={{
                    to: "/cgv",
                    text: "Conditions Générales de Ventes",
                  }}
                />
              }
            />
            <Route path="/cgv" element={<CGVentesPage />} />
            <Route path="/login" element={<LoginPage />}>
              <Route index element={<LoginForm />} />
              <Route path="cgu" element={<CGURegister />} />
            </Route>

            <Route path="/password" element={<LoginPage />}>
              <Route path="reset" element={<PasswordResetForm />} />
            </Route>

            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Provider>
    </>
  );

  return app;
}

const router = createBrowserRouter([{ path: "*", Component: Root }]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
