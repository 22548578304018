import { createAsyncThunk } from "@reduxjs/toolkit";
import Config from "../../Config";
import { RequestOptions } from "../../tools/RequestOptions";
import Cookies from 'js-cookie';
import { UserInfos } from "./AuthSlice";

const login = createAsyncThunk<UserInfos, { email: string, password: string }, { rejectValue: string }>('auth/login',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const response = await fetch(
                Config.endpoint + "user/authenticate",
                RequestOptions({ method: "POST", body: JSON.stringify({ email, password }) })
            );

            if (!response.ok) {
                const body = await response.json();
                return rejectWithValue(body.error ? body.error : "ERROR_UNKNOWN");
            } else {
                const { token, admin }: { token: string, admin: boolean } = await response.json();
                if (token) {
                    Cookies.remove('token');
                    Cookies.set('token', token, { expires: 7, secure: true });

                    // Check if it worked
                    const tokenSetWithLib = Cookies.get('token');
                    if (tokenSetWithLib !== token) {
                        document.cookie = `token=${token}`;
                        const tokenSetWithDoc = Cookies.get('token');
                        if (tokenSetWithDoc !== token) {
                            console.log("Cookies: ", document.cookie)
                            return rejectWithValue("ERROR_COOKIES");
                        }
                    }
                }
                return { email, admin };
            }
        } catch (error) {
            return rejectWithValue("ERROR_NETWORK");
        }
    });

const check = createAsyncThunk<UserInfos, void, { rejectValue: string }>('auth/check',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(Config.endpoint + "user/check", RequestOptions());
            if (response.ok) {
                return await response.json();
            } else {
                return rejectWithValue("NOT_CONNECTED");;
            }
        } catch (error) {
            console.error(error)
            return rejectWithValue("ERROR_NETWORK");
        }
    });

const logout = createAsyncThunk<void, void, { rejectValue: string }>('auth/logout',
    async (_, { rejectWithValue }) => {
        try {
            await fetch(Config.endpoint + "user/logout", RequestOptions({ method: "POST" }));
        } catch (error) {
            console.error(error);
            return rejectWithValue("ERROR_NETWORK")
        }
    });

export const AuthThunks = { login, logout, check };