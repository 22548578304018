import { PropsWithChildren } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export function CartelPage({
  children,
  extraLink,
}: PropsWithChildren & { extraLink?: { to: string; text: string } }) {
  const navigate = useNavigate();

  return (
    <div
      className="columns"
      style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
    >
      <div
        className="column is-half-desktop is-offset-one-quarter-desktop is-centered"
        style={{ flexDirection: "column", flexGrow: 1, display: "flex" }}
      >
        <div className="cartel" style={{ height: "20rem", flexGrow: 1 }}>
          {children}
        </div>
      </div>

      <div
        className="column is-half-desktop is-offset-one-quarter-desktop is-centered"
        style={{ flexGrow: 0 }}
      >
        {extraLink ? (
          <Link
            className="button is-off is-centered"
            style={{
              margin: "1rem auto auto auto",
              minWidth: "15rem",
              width: "90%",
              display: "block",
            }}
            to={extraLink.to}
          >
            {extraLink.text}
          </Link>
        ) : (
          <></>
        )}
        <button
          className="button is-off is-centered"
          style={{
            margin: "1rem auto auto auto",
            minWidth: "15rem",
            width: "90%",
            display: "block",
          }}
          onClick={() => navigate(-1)}
        >
          Retour
        </button>
      </div>
    </div>
  );
}
