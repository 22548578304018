import { Link, useLocation } from "react-router-dom";
import LoginSvg from "../imgs/login.svg";
import { useState } from "react";
import Config from "../Config";

export interface HeaderProps {
  useDelay: boolean;
}

function Header({ useDelay }: HeaderProps) {
  const [loginVisible] = useState(false);
  const location = useLocation();

  return (
    <>
      <section className="section">
        <header>
          <h1 className="bonjour">La Compagnie</h1>
          <h1 className="compagnie">Vous Ment</h1>
        </header>
      </section>
      {Config.showLogin ? (
        <div
          id="login-capsule"
          className={useDelay && !loginVisible ? "invisible" : ""}
          style={{
            animationName: useDelay && loginVisible ? `fadeIn` : "",
            animationDuration:
              useDelay && loginVisible ? `${fadeDuration}s` : "",
            animationTimingFunction: "ease-in",
          }}
        >
          <Link to={location.pathname === "/login" ? "/" : "/login"}>
            <button className="button is-rounded is-medium">
              <img className="image" src={LoginSvg} alt="Connexion" />
            </button>
          </Link>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Header;
