import { Link } from "react-router-dom";
import Logo from "../imgs/logo.png";
import { useState } from "react";

export function Navbar() {
  const [popupVisible, setPopupVisible] = useState(false);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <img
          src={Logo}
          alt="Logo de la compagnie"
          className="navbar-item"
          style={{ objectFit: "contain" }}
        />
        <Link to="/home" className="navbar-item">
          La Compagnie Vous Ment
        </Link>
      </div>

      <div className="navbar-end">
        <div className="navbar-item">
          <div className="buttons"></div>
        </div>
      </div>
    </nav>
  );
}
