import { createSlice } from "@reduxjs/toolkit";
import { StatusThunk } from "./StatusThunk";
import { StoryStatusApi } from "../../shared/StoryElements";
import { useSelector } from "react-redux";
import { RootState } from "../ReduxStore";

export interface StatusState {
    initialized: boolean,
    loading: boolean,
    error: string | null,
    status: StoryStatusApi | null,
}

export const StatusStateInitial: StatusState = {
    initialized: false,
    loading: false,
    error: null,
    status: null
}

const StatusSlice = createSlice({
    name: "status",
    initialState: StatusStateInitial,
    extraReducers: (builder) => {
        // activateStatus
        builder.addCase(StatusThunk.activateStatus.pending, (state) => {
            state.loading = true
            state.error = null
        })
        builder.addCase(StatusThunk.activateStatus.rejected, (state, { payload }) => {
            state.loading = false
            state.error = payload || "UNKNOWN_ERROR"
            state.status = null
        })
        builder.addCase(StatusThunk.activateStatus.fulfilled, (state, { payload }) => {
            state.loading = false
            state.status = payload
        })

        // currentStatus
        builder.addCase(StatusThunk.currentStatus.pending, (state) => {
            state.loading = true
            state.error = null
            state.status = null
        })
        builder.addCase(StatusThunk.currentStatus.rejected, (state, { payload }) => {
            state.initialized = true
            state.loading = false
            state.error = payload || "UNKNOWN_ERROR"
        })
        builder.addCase(StatusThunk.currentStatus.fulfilled, (state, { payload }) => {
            state.initialized = true
            state.loading = false
            state.status = payload
        })

        // newClue
        builder.addCase(StatusThunk.newClue.pending, (state) => {
            state.loading = true
            state.error = null
        })
        builder.addCase(StatusThunk.newClue.rejected, (state, { payload }) => {
            state.initialized = true
            state.loading = false
            state.error = payload || "UNKNOWN_ERROR"
        })
        builder.addCase(StatusThunk.newClue.fulfilled, (state, { payload }) => {
            state.initialized = true
            state.loading = false
            state.status = payload
        })
    },
    reducers: {
        stepChanged(state, action) {
            state.status = action.payload as StoryStatusApi
        },
    }
});

export const StatusSliceActions = StatusSlice.actions;

export default StatusSlice.reducer;

export function useStoryStatus(): StoryStatusApi | undefined {
    return useSelector((state: RootState) => {
        if (state.admin.status) {
            return state.admin.status;
        } else if (state.status.status) {
            return state.status.status;
        } else {
            return undefined;
        }
    });
}