import Cookies from "js-cookie";

const defaultRequest: RequestInit = {
    credentials: "include",
    method: "GET",
    headers: { "Content-Type": "application/json" },
};

export function RequestOptions(override: RequestInit = {}) {
    const token = Cookies.get("token");
    if (token) {
        const withToken = defaultRequest;
        if (!withToken.headers) {
            withToken.headers = {}
        }
        withToken.headers = { ...withToken.headers, ...{ Cookie: `token=${token}`, "compagnie-token": token } };
        return { ...withToken, ...override }
    } else {
        return { ...defaultRequest, ...override }
    }

}