import { Outlet, useNavigate } from "react-router-dom";
import "../../styles/login.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/ReduxStore";

function LoginPage() {
  const { logged } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  if (logged) {
    navigate("/home");
  }

  return (
    <>
      <div
        className="columns expand"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          className="column is-half-desktop is-offset-one-quarter-desktop is-centered expand"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {!logged ? <Outlet /> : <></>}
        </div>
      </div>
    </>
  );
}

export default LoginPage;
