export type CityName = "lyon" | "paris" | "toulouse"

const CitiesNames: { [key: string]: CityName } = {
    LYON: "lyon",
    PARIS: "paris",
    TOULOUSE: "toulouse"
}

const StoriesIndexes = {
    FIRST_MISSION: 1
}

const StepsIndexes = {
    FIND_ALPHA: 1,
    FIND_STATUE: 2,
    DAME_PERCHEE: 3,
    RENDEZ_VOUS: 4,
    THE_SMS: 5,
    LA_PLACE: 6,
    DEVOIR_MUT: 7,
    STARS: 8,
    FIRST_END: 9,
    SECOND_LETTER: 10,
    SECRET_FOUND: 11,
}

export const StoryConstants = {
    City: CitiesNames,
    Story: StoriesIndexes,
    Steps: StepsIndexes
}

export const StoryCodes = {
    SMS_VALIDATION: "ab85ss9*75",
    LYON_PLACE: "3763"
}