import { Navigate, useLocation } from "react-router";
import { AppDispatch, RESET_ACTION, RootState } from "../redux/ReduxStore";
import { useDispatch, useSelector } from "react-redux";
import { AuthThunks } from "../redux/auth/AuthThunk";

export function Logout() {
  const { loading, logged } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  if (!loading && logged) {
    dispatch(RESET_ACTION);
    dispatch(AuthThunks.logout());
  }

  if (!logged) {
    return <Navigate to="/" replace state={{ from: location }} />;
  } else {
    return <></>;
  }
}

export default Logout;
