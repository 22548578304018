import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { LoginAnimation, useLoginAnimation } from "./LoginTools";
import Config from "../../Config";
import { getLoginError } from "../../tools/Errors";
import { useDispatch, useSelector } from "react-redux";
import { AuthThunks } from "../../redux/auth/AuthThunk";
import { AppDispatch, RootState } from "../../redux/ReduxStore";
import { RequestOptions } from "../../tools/RequestOptions";
import { MIN_PASSWORD_SIZE } from "../../shared/ProjectConstants";
import { validateEmail } from "../../tools/TextTools";

function LoginForm() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const authState = useSelector((state: RootState) => state.auth);

  const [isHoverSignup, setHoverSignup] = useState(false);
  const [isSigningIn, setSigningIn] = useState(queryParameters.has("register"));
  const [acceptingEULA, setAcceptingEULA] = useState(false);

  const joinUs = "Rejoignez-nous !".toLowerCase();
  const connect = "Connectez-vous !".toLowerCase();

  const [targetText, setTargetText] = useState(connect);
  const [aboveText, setAboveText] = useState("ZONSADAEA-AZFA /".toLowerCase());

  const [triedToConnect, setTriedToConnect] = useState<boolean>(false);

  const [emailValue, setEmailValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [confirmationValue, setConfirmationValue] = useState<string>("");

  const [tooltipInfos, setTooltipInfos] = useState<{
    ok: Boolean;
    str: string;
    code?: string;
  } | null>(null);

  //
  async function registerUser() {
    if (!acceptingEULA) {
      setTooltipInfos({ ok: false, str: "Veuillez accepter les conditions" });
      return;
    }

    if (confirmationValue !== passwordValue) {
      setTooltipInfos({
        ok: false,
        str: "Les mots de passe ne correspondent pas",
      });
      return;
    }

    if (!validateEmail(emailValue)) {
      setTooltipInfos({
        ok: false,
        str: "Mail invalide",
      });
      return;
    }

    const response = await fetch(
      Config.endpoint + "user/register",
      RequestOptions({
        method: "POST",
        body: JSON.stringify({ email: emailValue, password: passwordValue }),
      })
    );

    if (!response.ok) {
      // ERROR
      const data = await response.json();
      var message = getLoginError(data.error);
      setTooltipInfos({ ok: false, str: message });
    } else {
      setSigningIn(false);
      setTooltipInfos({ ok: true, str: "Le compte a été créé" });
    }
  }

  async function authenticate() {
    if (!emailValue) {
      setTooltipInfos({ ok: false, str: "Le mail est obligatoire" });
      return;
    }

    if (!passwordValue) {
      setTooltipInfos({ ok: false, str: "Mot de passe obligatoire" });
      return;
    }

    if (!authState.loading) {
      dispatch(
        AuthThunks.login({ email: emailValue, password: passwordValue })
      );
      setTriedToConnect(true);
    }
  }

  if (
    triedToConnect &&
    authState.error !== null &&
    authState.error !== tooltipInfos?.code
  ) {
    var message = authState.error;
    switch (authState.error) {
      case "INVALID_EMAIL":
        message = "Mail inexistant";
        break;
      case "INVALID_PASSWORD":
        message = "Mauvais mot de passe";
        break;
    }
    setTooltipInfos({ ok: false, str: message, code: authState.error });
  }

  if (authState.logged) {
    navigate("/home");
  }

  // Above text animation
  const [aboveAnimation, setAboveAnimation] =
    useState<LoginAnimation>("switchLetter");

  useLoginAnimation(aboveAnimation, targetText, aboveText, setAboveText);

  return (
    <form
      className="login-form"
      onSubmit={(e) => {
        e.preventDefault();
        if (isSigningIn) {
          registerUser();
        } else {
          authenticate();
        }
      }}
    >
      <h2 className="is-family-monospace">{aboveText}</h2>
      {tooltipInfos ? (
        <h2
          className={`is-family-monospace login-tooltip ${
            tooltipInfos.ok ? "is-ok" : "is-nok"
          }`}
        >
          {tooltipInfos.str}
        </h2>
      ) : (
        <></>
      )}
      <div className="field">
        <p className="control">
          <input
            className="input is-family-monospace"
            type="email"
            placeholder="Email"
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
            name="email"
          />
        </p>
      </div>
      <div className="field">
        <p className="control">
          <input
            className="input is-family-monospace"
            type="password"
            placeholder="Mot de passe"
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
            name="password"
          />
        </p>
      </div>
      {isSigningIn ? (
        <>
          <div className="field">
            <p className="control">
              <input
                className="input is-family-monospace"
                type="password"
                placeholder="Confirmez le mot de passe"
                minLength={MIN_PASSWORD_SIZE}
                value={confirmationValue}
                onChange={(e) => setConfirmationValue(e.target.value)}
                name="confirmation"
              />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <label className="checkbox is-family-monospace">
                <input
                  type="checkbox"
                  checked={acceptingEULA}
                  onChange={(e) => setAcceptingEULA(!acceptingEULA)}
                ></input>
                Acceptez les <Link to="/login/cgu">Conditions</Link>
              </label>
            </p>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="field has-addons">
        <p className="control" style={{ flexGrow: 6 }}>
          <button
            className={`button is-fullwidth is-family-monospace ${
              !isHoverSignup ? "is-active" : "is-off"
            }`}
          >
            {isSigningIn ? "Valider inscription" : "Se connecter"}
          </button>
        </p>
        <p className="control" style={{ width: "38%" }}>
          <button
            className={`button is-fullwidth is-family-monospace ${
              isHoverSignup ? "is-active" : "is-off"
            } ${isSigningIn ? "is-red" : ""}`}
            onMouseEnter={() => {
              setHoverSignup(true);
              setAboveAnimation("switchLetter");
              setTargetText(!isSigningIn ? joinUs : connect);
            }}
            onMouseLeave={() => {
              setHoverSignup(false);
              setAboveAnimation("switchLetter");
              setTargetText(!isSigningIn ? connect : joinUs);
            }}
            onClick={(e) => {
              e.preventDefault();
              setSigningIn(!isSigningIn);
              setAcceptingEULA(false);
              setConfirmationValue("");
            }}
          >
            {isSigningIn ? "Retour" : "S'inscrire"}
          </button>
        </p>
      </div>
      {isSigningIn ? (
        <></>
      ) : (
        <div className="text-center" style={{ fontSize: "85%" }}>
          <Link to="/password/reset" className="lightLink">
            Mot de passe oublié ?
          </Link>
        </div>
      )}
    </form>
  );
}

export default LoginForm;
