import { createAction, createReducer } from "@reduxjs/toolkit";
import { StoryStatusApi } from "../../shared/StoryElements";

export interface AdminState {
    status?: StoryStatusApi | null
}
export const setAdminStatus = createAction<StoryStatusApi>("setAdminStatus");
export const unsetAdminStatus = createAction("unsetAdminStatus");

export const AdminReducer = createReducer<AdminState>(
    { status: null },
    (builder) => {
        builder
            .addCase(setAdminStatus, (state, action) => {
                state.status = action.payload;
            })
            .addCase(unsetAdminStatus, (state, action) => {
                state.status = null;
            });
    }
);
