import { useNavigate } from "react-router-dom";

export function BackButtonFlex() {
  const navigate = useNavigate();
  return (
    <div
      className="columns"
      style={{ alignItems: "flex-end", marginBottom: ".05rem" }}
    >
      <div className="is-fullwidth column">
        <button
          onClick={() => navigate(-1)}
          className="button is-medium is-fullwidth button-cartel-light"
        >
          Retour
        </button>
      </div>
    </div>
  );
}
