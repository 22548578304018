import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { LoginAnimation, useLoginAnimation } from "./LoginTools";
import Config from "../../Config";
import { RequestOptions } from "../../tools/RequestOptions";
import { MIN_PASSWORD_SIZE } from "../../shared/ProjectConstants";
import { validateEmail } from "../../tools/TextTools";

type Tooltip = {
  ok: Boolean;
  str: string;
  code?: string;
};

function PasswordResetForm() {
  const [queryParameters] = useSearchParams();

  const newPassword = "Choisir mot de passe".toLowerCase();
  const [targetText] = useState(newPassword);
  const [aboveText, setAboveText] = useState(
    "ZDFTELK WST PL DMWQO".toLowerCase()
  );

  const [tooltipInfos, setTooltipInfos] = useState<Tooltip | null>(null);

  // Above text animation
  const [aboveAnimation] = useState<LoginAnimation>("switchLetter");

  useLoginAnimation(aboveAnimation, targetText, aboveText, setAboveText);

  return (
    <form
      className="login-form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <h2 className="is-family-monospace">{aboveText}</h2>
      {tooltipInfos ? (
        <h2
          className={`is-family-monospace login-tooltip ${
            tooltipInfos.ok ? "is-ok" : "is-nok"
          }`}
        >
          {tooltipInfos.str}
        </h2>
      ) : (
        <></>
      )}
      {queryParameters.get("id") && queryParameters.get("token") ? (
        <NewPassword
          setTooltip={setTooltipInfos}
          id={queryParameters.get("id")}
          token={queryParameters.get("token")}
        />
      ) : (
        <SendMail setTooltip={setTooltipInfos} />
      )}
      <div
        className="text-center"
        style={{ fontSize: "85%", marginTop: "1rem" }}
      >
        <Link to="/login" className="lightLink">
          Retour à la connexion
        </Link>
      </div>
    </form>
  );
}

function SendMail(props: { setTooltip: (a: Tooltip | null) => void }) {
  const [emailValue, setEmailValue] = useState<string>("");

  async function sendRequest() {
    if (!validateEmail(emailValue)) {
      props.setTooltip({
        ok: false,
        str: "Mail invalide",
      });
      return;
    }

    const response = await fetch(
      Config.endpoint + "user/password/reset",
      RequestOptions({
        method: "POST",
        body: JSON.stringify({
          email: emailValue,
        }),
      })
    );

    if (response.status === 200) {
      props.setTooltip({ ok: true, str: "Mail envoyé" });
    } else if (response.status === 404) {
      props.setTooltip({ ok: false, str: "Compte inexistant" });
    } else {
      props.setTooltip({ ok: false, str: "Une erreur est survenue" });
    }
  }

  return (
    <div>
      <div className="field">
        <p className="control">
          <input
            className="input is-family-monospace"
            type="email"
            placeholder="Email"
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
            name="email"
          />
        </p>
      </div>
      <div className="field has-addons">
        <p className="control" style={{ flexGrow: 6 }}>
          <button
            className={`button is-fullwidth is-family-monospace is-active`}
            onClick={async (e) => {
              e.preventDefault();
              await sendRequest();
            }}
          >
            Envoyer mail
          </button>
        </p>
      </div>
    </div>
  );
}

function NewPassword(props: {
  setTooltip: (a: Tooltip | null) => void;
  id: string;
  token: string;
}) {
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [confirmationValue, setConfirmationValue] = useState<string>("");

  async function sendNewPassword() {
    if (confirmationValue !== passwordValue) {
      props.setTooltip({
        ok: false,
        str: "Les mots de passe ne correspondent pas",
      });
      return;
    }

    const response = await fetch(
      Config.endpoint + "user/password/new",
      RequestOptions({
        method: "POST",
        body: JSON.stringify({
          token: props.token,
          id: props.id,
          newPassword: passwordValue,
        }),
      })
    );

    if (response.status === 200) {
      props.setTooltip({ ok: true, str: "Changement confirmé" });
    } else if (response.status === 401) {
      props.setTooltip({ ok: false, str: "Requête invalide" });
    } else if (response.status === 403) {
      const jsonBody = await response.json();
      if (jsonBody && jsonBody.error === "TOKEN_EXPIRED") {
        props.setTooltip({ ok: false, str: "Le lien utilisé a expiré" });
      }
    } else {
      props.setTooltip({ ok: false, str: "Une erreur est survenue" });
    }
  }

  return (
    <div>
      <div className="field">
        <p className="control">
          <input
            className="input is-family-monospace"
            type="password"
            minLength={MIN_PASSWORD_SIZE}
            placeholder="Mot de passe"
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
            name="password"
          />
        </p>
      </div>
      <div className="field">
        <p className="control">
          <input
            className="input is-family-monospace"
            type="password"
            placeholder="Confirmez le mot de passe"
            minLength={MIN_PASSWORD_SIZE}
            value={confirmationValue}
            onChange={(e) => setConfirmationValue(e.target.value)}
            name="confirmation"
          />
        </p>
      </div>
      <div className="field has-addons">
        <p className="control" style={{ flexGrow: 6 }}>
          <button
            className={`button is-fullwidth is-family-monospace is-active`}
            onClick={async (e) => {
              e.preventDefault();
              await sendNewPassword();
            }}
          >
            Valider le changement
          </button>
        </p>
      </div>
    </div>
  );
}

export default PasswordResetForm;
